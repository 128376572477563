import html2canvas from "html2canvas";

export async function screenshot(element, token) {
  const canvas = await html2canvas(element, {
    useCORS: true, // prevent from blocked by browser CORS policy
  });

  if (canvas) {
    const base64Image = canvas.toDataURL("image/png");

    await fetch(`${process.env.REACT_APP_API_URL}/api/upload-image`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ media: base64Image }),
    });
    return Promise.resolve();
  }
}
